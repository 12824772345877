import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { map } from 'rxjs';
import { AuthState, getLoggedInUser } from '@frontend/auth-store';
import { select, Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class NoAuthGuard implements CanActivate {
  constructor(
    // private router: Router,
    private authStore: Store<AuthState>) {}

  canActivate() {
    return this.authStore.pipe(select(getLoggedInUser)).pipe(
      map((currentUser) => {
        // if (currentUser) {
        //   this.router.navigate(['/']);
        //   return false;
        // }
        return true;
      })
    );
  }
}
