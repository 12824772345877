<div class="body_bg">
  <frontend-artist-header></frontend-artist-header>
  <section class="profile-sec">
    <div class="container position-relative">
      <div class="profile-row d-flex justify-content-between"
           [ngStyle]="{'background-image': artist?.photo ? 'url(' + artist.photo + ')' : 'url(/assets/images/no-profile-picture-icon.svg)'}"
      >
        <div class="profile-col d-flex flex-wrap align-items-center">

          <div class="profile-img profile-img-wrapper">

            <img [src]="artist?.photo || '/assets/images/no-profile-picture-icon.svg'" class="img-fluid "/>
            <span class="store-name-logo" *ngIf="!(artist?.photo)">
                {{((artist?.first_name || '') || (artist?.last_name || ''))?.substring(0, 2) | uppercase}}
            </span>
          </div>
          <div class="profile-content">
            <h3>{{(artist?.first_name || '') + ' ' + (artist?.last_name || '')}}</h3>
            <div class="pro-btn d-flex">
              <a href="https://play.google.com/store/apps/details?id=com.kwot.music.android" class="cursor-pointer" target="_blank">
                <img src="assets/images/play-store.png" alt>
              </a>

              <a href="https://apps.apple.com/in/app/kwot/id1641283024" class="cursor-pointer" target="_blank">
                <img src="assets/images/appstore.png" alt>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="followers-row">
        <div class="followers col">
          <span>{{artist?.followers || 0}}</span>
          <h4>Followers</h4>
        </div>
        <div class="following col">
          <span>{{artist?.followings || 0}}</span>
          <h4>Following</h4>
        </div>
      </div>
    </div>
  </section>
  <section class="play-list">
    <ng-container *ngIf="!artist?.feeds?.length">
      <h2 class="items-title text-capitalize">
        No Feeds Available
      </h2>
    </ng-container>
    <div *ngFor="let feed of artist?.feeds; let i = index">

      <div class="container mt-75">
        <h2 class="items-title text-capitalize">
         {{feed.title}}
        </h2>
        <swiper [config]="OrganiceMusicConfig" class="swiper mySwiper">
          <div class="swiper-wrapper" *ngIf="feed.items.length > 0">
            <div class="swiper-slide" *ngFor="let item of feed.items; let i = index">
              <div class="slide_card">
                <div class="slide_image">
                  <img [src]="item.thumbnail || item.photo || 'https://kwot-music.s3.amazonaws.com/63971029624be6a835fe782a/podcast/IFi9gjrOuBgIwVON44tTePbIyjCqfs.jpg'" /></div>
                <div class="slide_content">
                  <h1>{{item.title}}</h1>
                  <p>{{artist?.first_name + ' ' + artist?.last_name}}</p>
                </div>
              </div>
            </div>
          </div>
        </swiper>
      </div>
    </div>
  </section>

  <section class="artist_footer_section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section_footer_heading">
            <h1>
              Start sharing your amazing content now!
            </h1>

            <a href="{{appConfig.artistUrl}}" target="_blank">
              <button class="btn-action">Get access</button>
            </a>
          </div>
        </div>
      </div>
      <frontend-artist-footer></frontend-artist-footer>
    </div>
  </section>
</div>
