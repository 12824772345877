import {Component, Inject} from '@angular/core';
import {APP_CONFIG} from "@frontend/app-config";

@Component({
  selector: 'frontend-poadcast-episode-page',
  templateUrl: './podcast-episode-page.component.html',
  styleUrls: ['./podcast-episode-page.component.scss'],
})
export class PodcastEpisodePageComponent {
  constructor(
    @Inject(APP_CONFIG) public appConfig: any,
  ) {
  }

  getDeviceInfo() {
    const userAgent = window.navigator.userAgent || window.navigator.vendor;
    const platform = window.navigator.platform;

    let os = "unknown";
    let browser = "unknown";
    if (/android/i.test(userAgent)) {
      os = "Android";
    } else if (/iPad|iPhone|iPod/.test(userAgent)) {
      os = "iOS";
    } else if (/Win/.test(platform)) {
      os = "Windows";
    } else if (/Mac/.test(platform)) {
      os = "Mac OS";
    } else if (/Linux/.test(platform)) {
      os = "Linux";
    }

    if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) {
      browser = "Safari";
    } else if (/Chrome/.test(userAgent)) {
      browser = "Chrome";
    } else if (/Firefox/.test(userAgent)) {
      browser = "Firefox";
    } else if (/Edge/.test(userAgent)) {
      browser = "Edge";
    } else if (/MSIE|Trident/.test(userAgent)) {
      browser = "Internet Explorer";
    } else {
      browser = "Unknown";
    }

    return {
      userAgent: window.navigator.userAgent,
      platform: window.navigator.platform,
      appVersion: window.navigator.appVersion,
      language: window.navigator.language,
      os,
      browser
    };
  }

  ngOnInit() {
        let device = this.getDeviceInfo();
    const os = device.os;
    const browser = device.browser;
    const webLink = "https://play.google.com/store/apps/details?id=com.kwot.music.android";
    const appStoreLink = "https://apps.apple.com/in/app/kwot/id1641283024";

    let linkToOpen;

    if (os === "Linux" || os === "Windows" || os === "Android") {
      linkToOpen = webLink;
    } else if (os === "iOS" || os === "Mac OS" || browser === 'Safari') {
      linkToOpen = appStoreLink;
    } else {
      linkToOpen = webLink;
    }
    // Open the determined link in a new tab
    const link = document.createElement('a');
    link.href = linkToOpen;
    link.target = '_blank';
    document.body.appendChild(link);
    link.click();
  }
}
