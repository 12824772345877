<div class="main">
  <div class="modal-body">
    <div class="content d-flex align-items-center justify-content-center flex-column p-4 p-md-5">
      <h3 class="text-center">This content is available on the app. Please download the app for your platform</h3>
      <div class="pro-btn d-flex flex-wrap">
        <a href="https://play.google.com/store/apps/details?id=com.kwot.music.android" class="cursor-pointer" target="_blank">
          <img src="assets/images/play-store.png" alt>
        </a>

        <a href="https://apps.apple.com/in/app/kwot/id1641283024" class="cursor-pointer" target="_blank">
          <img src="assets/images/appstore.png" alt>
        </a>
      </div>
    </div>
  </div>
</div>
